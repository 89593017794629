import React from "react";
import { Link } from "../../Link";
import classNames from "./Menu.module.css";
import { useTranslation } from "react-i18next";

const Menu = (props) => {
  const [t] = useTranslation();

  return (
    <div
      className={classNames.container}
      style={props.menu ? { transform: "translateX(0px)" } : { transform: "translateX(100%)" }}
    >
      <div className={classNames.header}>
        <Link to="/" onClick={props.close}>
          <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3207 17.7927L16.2215 21.6228L19.2131 18.6894L8.09229 7.78467V29.6096H8.10286L12.3207 25.4737V17.7927Z"
              fill="white"
            />
            <path
              d="M32.2684 0L21.8928 10.1687L11.5225 0H0V37.4667H11.5225L31.4703 17.9067V25.4788L35.6988 29.6251V7.89864L9.77302 33.3204H4.22846V4.14627H9.77302L21.8928 16.0305L34.0179 4.14627H39.5625V33.3204H34.0179L27.2524 26.6864L24.2608 29.6199L32.2684 37.4667H43.791V0H32.2684Z"
              fill="white"
            />
          </svg>
        </Link>
        <div className={classNames.btn} onClick={props.close}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19" stroke="white" strokeWidth="2" />
            <line
              y1="-1"
              x2="24.6577"
              y2="-1"
              transform="matrix(0.683452 -0.729995 0.651248 0.758865 12.1477 29)"
              stroke="white"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="24.6577"
              y2="-1"
              transform="matrix(0.683452 0.729995 -0.651248 0.758865 11 11)"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
      <div className={classNames.body}>
        <Link to="/explore" onClick={props.close}>
          {t("navbar.explore", "Explore")}
        </Link>
        <Link to="/features" onClick={props.close}>
          {t("navbar.features", "Features")}
        </Link>
        <Link to="/faq" onClick={props.close}>
          {t("navbar.faq", "FAQ")}
        </Link>
        <Link to="/buying" onClick={props.close}>
          {t("navbar.buying", "For consultant buyers")}
        </Link>
      </div>
      <div className={classNames.body}>
        <Link to="/signup" onClick={props.close}>
          {t("navbar.signup", "Signup")}
        </Link>
        <a href="https://app.momang.io">{t("navbar.login", "Login")}</a>
      </div>
      <div className={classNames.footer}>
        <Link to="/booking" onClick={props.close}>
          {t("navbar.booking", "Book quick demo")}
        </Link>
      </div>
    </div>
  );
};

export default Menu;
