import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroArea from "./HeroArea/HeroArea";
import classNames from "./Features.module.css";

import Man from "../../assets/images/hopp_dude_new.png";
import Donuts from "../../assets/images/donuts.jpg";
import Balloons from "../../assets/images/balloons_hires.png";
import USP from "../../components/USP/USP";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/ResponsiveNav/Nav";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Buying = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const usp1 = {
    img: Man,
    title: t("buying.usp1.title"),
    subTitle: t("buying.usp1.subtitle"),
    description: t("buying.usp1.description"),
    btn: null,
    leftImage: true,
  };
  const usp2 = {
    img: Balloons,
    title: t("buying.usp2.title"),
    subTitle: t("buying.usp2.subtitle"),
    description: t("buying.usp2.description"),
    btn: null,
    leftImage: false,
  };
  const usp3 = {
    img: Donuts,
    title: t("buying.usp3.title"),
    subTitle: t("buying.usp3.subtitle"),
    description: t("buying.usp3.description"),
    btn: null,
    leftImage: true,
  };

  return (
    <>
      <Helmet>
        <title>{t("buying.meta.title", "Momang")}</title>
        <meta name="description" content={t("buying.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#ffd2d5f1"} />
      <Nav bg={"#ffd2d5f1"} />
      <HeroArea history={props.history} />
      <div className={classNames.upsContainer}>
        <USP details={usp1} />
        <USP details={usp2} />
        <USP details={usp3} />
      </div>
      <Footer />
    </>
  );
};

export default Buying;
