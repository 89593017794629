import React from "react";
import classNames from "./HeroArea.module.css";

import Popcorn from "../../../assets/images/popcorn.png";
import { useTranslation } from "react-i18next";

const HeroArea = () => {
  const [t] = useTranslation();

  return (
    <div className={classNames.container}>
      <div className={classNames.text}>
        <div className={classNames.title}>
          <h1>{t("buying.hero.title", "Handle All Your Consultants with Ease")}</h1>
        </div>
        <div className={classNames.description}>
          <p>
            <strong>
              {t(
                "buying.hero.subtitle",
                "Tired of juggling multiple vendors and wasting time sorting through hundreds of CVs? Or are you worried about being overly reliant on a single supplier?"
              )}
            </strong>
          </p>
          <br />
          <p>
            {t(
              "buying.hero.description",
              "Streamline your consultant management process with our all-in-one digital solution. Our platform simplifies every aspect of sourcing, contracting, and managing consultants—ensuring you find the best talent, optimize costs, and maintain complete control over your operations. Experience effortless management of your consultant workforce today."
            )}
          </p>
        </div>
      </div>
      <div className={classNames.image}>
        <img src={Popcorn} alt="Popcorn" />
      </div>
    </div>
  );
};

export default HeroArea;
